<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDAProjectDetail',
  metaInfo: { title: 'Detail Project' },
  extends: View,

  mixins: [
    LoadSections([
      'KDAProjectDetailHeader',
      'KDAProjectDetailDescription',
      'KDAProjectDetailPoint',
      'KDAProjectDetailQuote',
      'KDAProjectDetailList',
      'KDABookBanner'
    ])
  ],

  props: {
    id: {
      type: String,
      default: 'project'
    }
  }
};
</script>
